// gatsby-browser.js
import React from 'react';
import { PageStateProvider } from './src/components/context/PageStateContext';

export const wrapRootElement = ({ element }) => (
    <PageStateProvider>{element}</PageStateProvider>
);

// export const onRouteUpdate = ({ location }) => {
//     if (typeof window !== 'undefined') {
//     const script = document.createElement('script');
//     script.src = 'https://www.instagram.com/embed.js?v=2';
//     script.async = false;
  
//     document.body.appendChild(script);
// }
//   };
